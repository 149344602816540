<template>
    <div class="item_box">
        <el-image
            class="logo cursor"
            style="width: 120px; height: 120px"
            :src="item.logo?item.logo:DEFAULT_LOGO"
            fit="corver"></el-image>
        <div class="lf_content">
            <div  class="companyName textOverflow cursor" :title="item.fullname_en |priorFormat(item.fullname_zh,LOCALE)">{{item.fullname_en |priorFormat(item.fullname_zh,LOCALE) }}</div>
            <div class="three">
                <div>
                    <span class="lab">协会简称：</span>
                    <span class="con textOverflow" :title="item.shortname_en | priorFormat(item.shortname_zh,LOCALE)">{{ item.shortname_en | priorFormat(item.shortname_zh,LOCALE)}}</span>
                </div>
                <div>
                    <span class="lab" style="width:56px">所在地：</span>
                    <span class="con textOverflow">{{ item.country | countryCityFormat(
                      item.region_en,
                      item.region_zh,
                      LOCALE
                    )
                }}</span>
                </div>
                <div>
                    <span class="lab">官网地址：</span>
                    <span @click="open_web" class="con mainColor textOverflow cursor" :title="item.website|textFormat">{{ item.website|textFormat }}</span>
                </div>
            </div>
            <div class="det">
                <span v-if="item.desc_zh" v-html="item.desc_zh"></span>
                <span v-else-if="item.desc_en" v-html="item.desc_en"></span>
                <span v-else >-</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            item:{}
        }
    },
    created(){
        this.getItem()
    },
    methods:{
        open_web(){
            if(this.item.website.indexOf('http')!=-1){
                window.open(this.item.website)
            }else{
                window.open('http://'+this.item.website)
            }
            
        },
        async getItem(){
            let params={
                source:this.PJSource,
                association_alliance_id:this._decode(this.$route.query.parameter).id
            }
            let res = await this.$store.dispatch(
                "API_index/association_alliance",
                params
            );
            this.item=res.data[0]
        }
    },
}
</script>
<style lang="less" scoped>
     .item_box{
        margin: auto;
        margin-top: 16px;
        margin-bottom: 60px;
        width: 1200px;
        background: #FFFFFF;
        padding: 30px 24px;
        display: flex;
    }
    .lf_content{
        width: 1008px;
        height: 100%;
        box-sizing: border-box;
        margin-left: 24px;
    }
    .companyName{
        max-width:400px;
        height: 28px;
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        line-height: 28px;
        margin-bottom: 20px;
    }
    .three{
        display: flex;
        justify-content: space-between;
        >div{
            display: flex;
            .lab{
                width: 70px;
                height: 20px;
                font-size: 14px;
                font-weight: 800;
                color: #333333;
                line-height: 20px;
            }
            .con{
                width: 160px;
                height: 20px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 20px;
            }
        }
    }
    .det{
        width: 1008px;
        margin-top: 16px;
        .look_more{
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #1989FA;
            line-height: 20px;
        }
    }
</style>